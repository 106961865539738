<template>
  <v-container >
    <v-row
    >
      <v-col lg="1" cols="12">
        <a
            href="/"
        ><v-img
            :src="require('../assets/Bouton retour.png')"
            contain
            max-height="34"
        /></a>
      </v-col>
    </v-row>
    <v-row
        justify="center"
    >
        <router-link to="/documentation/legal">
          <v-img
              :src="require('../assets/documentation/Com-bilbio@2x-100.jpg')"
              contain
              height="150"
              width="175"
          />
        </router-link>
        <a
            href="/documentation/ressources"
            class="subheading mx-3 mt-n12"
        >
          <v-img
              :src="require('../assets/documentation/RH_1@2x-100.jpg')"
              contain
              height="150"
              width="175"
          />
        </a>
        <a
            href="/documentation/process"
            class="subheading mx-3"
        >
          <v-img
              :src="require('../assets/documentation/process@2x-100.jpg')"
              contain
              height="150"
              width="175"
          />
        </a>
    </v-row>
    <row
        justify="center"
    >
      <a
          href="/documentation"
          class="subheading mx-3"
      >
        <v-img
            :src="require('../assets/documentation/bibliografruit@2x-100.jpg')"
            contain
            height="300"
        />
      </a>
    </row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>